


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class NovaTransfer extends Vue {
  @Prop({ required: true })
  private item: any;
}
