






































import { Inject } from '@cds/common';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { massSet } from '@/services/massage-service';
import { UserGroupService } from '@/services/user-group-service';

@Component({
  components: {

  }
})
export default class userGroupChoose extends Vue {
  @Prop({ default: true }) private chooseTitle!: String; 
  @Prop({ default: true }) private groupId!: String; 
  @Prop({ default: true }) private formVisible!: Boolean; 
  @Prop({ default: true }) private groupList!: any; 
  
  public form: any = {
    groupId:this.groupId,
    pages: 1,
    pageSize: 5,
    currentPage: 1
  };
  public visible: Boolean = this.formVisible;//定义一个本地的 data 属性并将这个 prop 用作其初始值，同步对组件的修改，再通知父组件更新
  private tableData: any[] = [];
  private applications: any[] = [];
  private groupArr: any[] = [];
  private currentRow: any = '';
  public loading: Boolean = false;

  @Inject(UserGroupService) private userGroupService!: UserGroupService;

  public async created(): Promise<void> {
    await this.getList()
  }
  private mounted() {
  }
  // 重置
  public reset() {
    this.form = {
      groupId:this.groupId,
      pages: 1,
      pageSize: 5,
      currentPage: 1
    }
    this.search()
  }
  // 搜索
  public search() {
    this.getList()
  }
  //分页相关
  public handleSizeChange(size: number) {
    this.form.pageSize = size;
    this.getList();
  }
  public handleCurrentChange(current: number) {
    this.form.currentPage = current;
    this.getList();
  }
  //获取数据源
  public async getList() {
    this.form.pageSize = Number(this.form.pageSize);
    this.form.currentPage = Number(this.form.currentPage);
    let res = await this.userGroupService.groupQueryList(this.form);
    this.tableData = res.records;
    this.form.total = res.total;
    this.form.pages = res.pages;
    this.form.pageSize = res.size;
    this.form.currentPage = res.current;
  }
  
  @Emit('close')
  public handleCurrent(val:any): Promise<any> {
    this.currentRow = val; 
    this.close();
    return this.currentRow
  }
  @Emit('close')
  public close(): Boolean {
    this.visible = false;
    return false;
  }
}
