

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { UserGroupService } from '@/services/user-group-service';
import ModifyUserGroup from '@/components/UserGroup/ModifyUserGroup.vue';
import userGroupCreateChat from '@/components/UserGroup/userGroupCreateChat.vue';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';
import { cloneDeep, isArray } from 'lodash';

@Component({
  components: {
    userGroupCreateChat
  }
})
export default class UserGroupTagGroup extends Vue {
  public title: String = '创建/编辑群聊或标签';
  public text: String = '';
  public btnBalg: Boolean = false;
  public groupList:any ='';
  public dialogFormVisible: Boolean = false;
  private loading: boolean = false;
  private allAuthorizedUserGroups: any[] = [];
  private sortName = 'name';
  private options = [
    { label: '按名称正序查看', value: 'name' },
    { label: '按名称逆序查看', value: 'name-d' },
    {label: "按创建时间正序查看",value: "createTime"},
    {label: "按创建时间逆序查看",value: "createTime-d"}
  ];


  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  @Inject(DialogService) private dialogService!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;

  @Prop({ required: true })
  private allGroups: any;

  @Prop({ required: true })
  private activeUserGroup: any;

  @Prop({ required: false, default: true })
  private hasPharmaPermission: any;
  @Prop({ required: false, default: true })
  private hasHrCorePermission: any;
  @Prop({ required: false, default: true })
  private hasNonFfPermission: any;

  @Watch('allGroups')
  private async handleAllGroupsChange(value: any) {
    this.allAuthorizedUserGroups = await this.userGroupService.getAllUserGroups();
    this.allGroups = value;

  }

  // @Watch('sortName')
  // private async handleSortChange(value: any) {
  //   this.changeSortType(value);
  // }

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get isFoldTag(){
    return this.activeUserGroup.length === 1
  }

  public async created(): Promise<any> {
    this.allAuthorizedUserGroups = await this.userGroupService.getAllUserGroups();
  }

  public isActiveUserGroup(id: string): Boolean {
    return !!this.activeUserGroup.find((item: any) => item.id == id);
  }


  public hasPermission(item: any): Boolean {
    // const { dataPerm, id, name, type } = item;
    // if (dataPerm && isArray(dataPerm) && dataPerm.length > 0) {
    //   dataPerm.forEach((per: any) => {
    //     if (per == TYPESTATU.PHARMA && !this.hasPharmaPermission) {
    //       return false;
    //     }

    //     if (per == TYPESTATU.HRCORE && !this.hasHrCorePermission) {
    //       return false;
    //     }
    //   });
    // }
    // return true;
    return this.allAuthorizedUserGroups.findIndex((userGroup: any) => item.id == userGroup.id) !== -1
  }

  public async updateUserGroup(item: any): Promise<void> {
    console.log('updateUserGroup', item);
    const key = uuid();
    const res = await this.dialogService.open(
      '用户分组规则',
      ModifyUserGroup,
      {
        config: [{}],
        // formData: this.userGroupDetail,
        userGroupId: item.id,
        type: 'hr core',
        disablePharmaTab: !this.hasPharmaPermission,
        disableHrcoreTab: !this.hasHrCorePermission,
        disableNonffTab: !this.hasNonFfPermission,
        key
      },
      {
        height: 'auto',
        key
      }
    );
    if (res.action === CloseStatus.confirm) {
      // this.$set(this.activeUserGroup,0,{...item,'refreshTime': Date.now()});
      this.$emit('modifyUserGroupConfirm');
      this.$emit('changeActiveUserGroup', item);
      // 重新调取查询
      // this.getUserGroups(type);
    }
  }

  private clickTag(val: any) {
    console.log('click', val);
    const { id, name, type } = val;
    this.allGroups.forEach((group: any) => {
      if (group.id == id) {
        this.$emit('changeActiveUserGroup', group);
      }
    });
  }

  private async handleClose(item: any): Promise<void> {
    let message: string = '确定要删除吗?';
    let res = await this.userGroupService.getRelatedApplicationsByUserGroupId(
      item.id
    );

    if (res && isArray(res) && res.length > 0) {
      message = message
        .concat('该分组已关联到应用<br/>')
        .concat(res.map((e: any) => e.channelName).join('<br/>'));
    }
    try {
      await this.$confirm(message, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        dangerouslyUseHTMLString: true
      });
      // if (item.type == DivisionOrg.HRCORE) {
      await this.userGroupService.deleteUserGroupForHRCore(item.id);
      // } else {
      //   await this.userGroupService.deleteUserGroup(item.id);
      // }
      // await this.initAllUserGroups('');
      if (item.id.toString() === this.activeUserGroup[0].id.toString()) {
        // this.activeUserGroup = this.allGroups[0];
        this.$emit('refreshUserGroupList', item);
        // this.changeActiveUserGroup(this.allGroups[0])
        this.$emit('changeActiveUserGroup', this.allGroups[0]);
      }
    } catch (e) {
      this.$notify.info({
        title: '取消',
        message: '删除取消'
      });
    }
  }

  public handleCommand(action: string, item: any, index: number): void {
    this.$set(this.allGroups, index, { ...item, popoverVisible: false });
    if (action === 'edit') {
      this.updateUserGroup(item);
    } else if (action === 'delete') {
      this.handleClose(item);
    }
  }

  //创建标签和群聊
  public async handleChat(action: string, item: any, index: number) { 
    this.$set(this.allGroups, index, { ...item, popoverVisible: false });
   
    if (action === 'edit') {        
      this.dialogFormVisible = true;
    } else if (action === 'delete') {
      let groupChat = this.groupList.groupChat;
      let groupTag = this.groupList.groupTag;

      try {
        await this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });
        if(groupChat.name!=null && groupChat.name!=''){ //删除群聊          
          let res = await this.userGroupService.groupChatDelete(item.id);
          this.$message({message: '删除成功', type: 'success'});
        }
        if(groupTag.tagName!=null && groupTag.tagName!=''){ //删除标签   
          let res = await this.userGroupService.groupTagDelete(item.id);
          this.$message({message: '删除成功', type: 'success'});
        }
      } catch (e) {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      }
      
    }
   
  }
   //得到子组件的值
   public selectChange(e: any): void {
    if(e==false){
      this.dialogFormVisible = false;  
      return;
    }   
  }
  public async clickMenu(event: any, item: any) {
    let res = await this.userGroupService.getHRCoreUsergroupById(item.id);
    this.groupList = res; 
    let groupChat = this.groupList.groupChat;
    let groupTag = this.groupList.groupTag;
    this.text = ''
    this.btnBalg = false;
    if(groupChat.name!=null && groupChat.name!=''){ //删除群聊   
       this.text = '解散群聊'
       this.btnBalg = true;
    }
    if(groupTag.tagName!=null && groupTag.tagName!=''){ //删除标签   
      this.text = '删除标签'
      this.btnBalg = true;
    }

    event.preventDefault();
    event.stopPropagation();
    item.popoverVisible = true;  

  }

  public changeSortType(value:any){
    let availableGroups = cloneDeep(this.allGroups);
    if (value == 'name') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        return (a.name).localeCompare(b.name);
      });
    } else if (value == 'name-d') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        return (b.name).localeCompare(a.name);
      });
    } else if (value == 'createTime') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        if(a && b){
          let timeA = Date.parse(a.createTime)
          let timeB = Date.parse(b.createTime)
          return timeA - timeB;
        }
        return  false
      });
    } else if (value == 'createTime-d') {
      this.allGroups = availableGroups.sort(function compare(a: any, b: any) {
        if(a && b){
          let timeA = Date.parse(a.createTime)
          let timeB = Date.parse(b.createTime)
          return timeB - timeA;
        }
        return  false
      });
    }
  }
}
