







































































































































































import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import OptionsTemp from './OptionsTemp.vue';
import { UserGroupService } from '@/services/user-group-service';
import {
  isString,
  debounce,
  isArray,
  some,
  lowerCase,
  cloneDeep,
  forEach,
  filter,
  find,
  findIndex
} from 'lodash';
import { PharmaSearchConfig, OncoSearchConfig } from './index.config';

@Component({
  name: 'nova-transfer',
  components: { OptionsTemp }
})
export default class NovaTransfer extends Vue {
  public loading: boolean = false;
  public leftSearch: string = '';
  public rightSearch: string = '';
  public leftisIndeterminate: boolean = true;
  public rightisIndeterminate: boolean = true;
  public placeholder: string = '搜索用户名、邮箱、员工号、Postline';
  public quickQueryUserList = debounce(this.queryUser, 800);
  public removedDropdownlist: Dict<boolean> = {};
  public searchConfig: Dict<any> = [];
  private fillIntervalId!: number;
  // 初始化数组
  private leftdata: any = [];
  private rightdata: any = [];
  private leftCheckList: any = [];
  private rightCheckList: any = [];
  private showDropSearch: boolean = true; // 搜搜菜单
  // 左侧可选
  private leftcheckAll: boolean = false;
  private rightcheckAll: boolean = false;
  private searchResults!: Dict<any[]>;
  private searchForm: any = {};
  private initDataCount!: number; // 通过搜索获取的数组长度 每次搜索后重新赋值
  private isSearch: boolean = true; // 是否搜索的道德flag 默认false

  @Prop({ required: true })
  private userGroupRoles!: Dict<any>;

  @Prop({ required: true })
  private type!: string;

  @Model('change', { type: Array, default: () => [] })
  private rightInitData!: any[];

  @Inject(UserGroupService)
  private userGroupService!: UserGroupService;

  // i8n
  @Inject(I18nService)
  private i18nSvc!: I18nService;

  @Watch('rightInitData')
  public handleinitRightData(value: Dict<any>): void {
    this.rightdata = value;
  }

  @Watch('userGroupRoles')
  public async handleinitUserGroupRoles(value: Dict<any>): Promise<void> {
    this.showDropSearch = true;
    // this.searchForm = {};
    await this.getSearchOption({});
    this.leftSearch = '';
    this.searchForm = value;
    this.removedDropdownlist = {};
    forEach(this.searchForm, (item, key) => {
      this.removedDropdownlist[key] = isArray(item) && item.length > 0;
    });
  }

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  get filterEffectUserNumbers() {
    if (isArray(this.rightdata) && this.rightdata.length > 0) {
      const res = this.rightdata.reduce(
        (total: number, currentValue: Dict<any>) =>
          total + currentValue.effectUserNumbers,
        0
      );
      return res;
    }
    return 0;
  }

  public getLabel(
    options: Dict<any>[],
    val: string,
    key: string,
    removeUnexists?: boolean
  ): string | undefined {
    const target = find(options, (e) => e.value === val);
    if (!target) {
      const index = findIndex(this.searchForm[key], (e) => e === val);
      if (index > -1) {
        if (removeUnexists) {
          this.searchForm[key].splice(index, 1);
        }
        if (this.searchForm[key].length === 0) {
          this.removedDropdownlist[key] = false;
        }
      }
      return;
    } else {
      return target.label || target.value;
    }
  }

  // 搜索dropdown
  public dropdownSearch(): void {
    this.showDropSearch = !this.showDropSearch;
  }

  // 左侧逻辑
  public leftCheckAllChange(val: boolean): void {
    this.leftCheckList = val ? this.leftdata.slice(0) : [];
    this.leftcheckAll = val;
  }
  // 移动到左侧
  public moveToLeft(): void {
    this.rightcheckAll = this.rightcheckAll
      ? !this.rightcheckAll
      : this.rightcheckAll;
    this.rightCheckList.forEach((item: any) => {
      const index = this.rightdata.findIndex(
        (rightItem: any) => rightItem.userId === item.userId
      );
      this.rightdata.splice(index, 1);
      // 从右侧穿梭回来的数据要恢复原有的生效人数 effectUserNumbers 等于 effectUserNumbersSave 缓存人数
      item.effectUserNumbers = item.effectUserNumbersSave;
      this.leftdata.push(item);
    });
    this.rightdata.forEach((item: any) => {
      // 如果筛选出来的结果 穿梭到左边那么右边的 flag为N的生效人数全部置为1
      if (item.quickSearchFlag === 'N') {
        item.effectUserNumbers = 1;
      }
    });
    this.$emit('change', this.rightdata);
    this.rightCheckList = [];
  }

  // 右侧逻辑
  public rightCheckAllChange(val: boolean): void {
    this.rightCheckList = val ? this.rightdata.slice(0) : [];
    this.rightisIndeterminate = false;
  }
  // 数据移动到右侧
  public moveToRight(): void {
    this.leftcheckAll = this.leftcheckAll
      ? !this.leftcheckAll
      : this.leftcheckAll;
    this.leftCheckList.forEach((item: any) => {
      const index = this.leftdata.findIndex(
        (leftItem: any) => leftItem.userId === item.userId
      );
      // 通过搜索查询的结果 isSearch 且 全部穿梭 与 initDataCount 搜索得到的数组长度相等 不需要改变任何数据
      // 通过搜索查询的结果 isSearch 且 全部穿梭 与 initDataCount 搜索得到的数组长度不相等 穿梭过去的生效人数为1
      if (this.isSearch && this.initDataCount !== this.leftCheckList.length) {
        item.effectUserNumbers = 1;
        this.$emit('flag', 'N');
      }
      this.rightdata.push(item);
      if (index !== -1) {
        this.leftdata.splice(index, 1);
      }
    });
    // 分条传送的 如果最后右边的数组长度等于初始长度那么 所有右边生效人数取缓存生效人数
    if (this.isSearch && this.rightdata.length === this.initDataCount) {
      // 设置有结构
      this.$emit('flag', 'Y');
      this.rightdata.forEach((item: any) => {
        item.effectUserNumbers = item.effectUserNumbersSave;
      });
    }
    this.$emit('change', this.rightdata);
    this.leftCheckList = [];
  }

  public rightSearchChange(val: string): void {
    if (!val) {
      this.rightdata = cloneDeep(this.rightInitData);
    } else {
      this.rightdata = this.rightInitData.filter((item: any) => {
        return some(
          [item.name, item.employeeNumber, item.email, item.positionLine],
          (e) => e && lowerCase(e).indexOf(lowerCase(val)) > -1
        );
      });
    }
  }

  // 搜索确定
  public async confirmSearch(): Promise<void> {
    // 筛选确定后删除右边穿梭数据
    // 通过搜索查询 isSearch 置为true
    this.rightCheckList = [];
    this.searchForm.division = this.type.toLowerCase();
    await this.queryuserlist(this.searchForm, true);
    this.isSearch = true;
    this.showDropSearch = false;
    this.$emit('handleChangeRoles', this.searchForm);
  }

  public resetSearch(): void {
    forEach(this.searchForm, (item, key) => {
      if (key !== 'division') {
        this.searchForm[key] = [];
      }
    });
    this.removedDropdownlist = {};
    this.getSearchOption();
  }

  public handleCloseTag(index: number, propKey: string): void {
    if (
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    ) {
      this.searchForm[propKey].splice(index, 1);
      this.handleNoTag(this.searchForm[propKey], propKey);
    }
  }

  public handleOptionsChange(isOpen: boolean, propKey: string): void {
    // if (isOpen) {
    //   const target = find(this.searchConfig, (item) => item.prop === propKey);
    //   target.options = this.searchResults[propKey];
    // }
    if (
      !isOpen &&
      isArray(this.searchForm[propKey]) &&
      this.searchForm[propKey].length > 0
    ) {
      this.removedDropdownlist[propKey] = true;
      this.getSearchOption();
    }
  }

  public handleNoTag(val: string[], propKey: string): void {
    if (isArray(val) && val.length === 0) {
      this.removedDropdownlist[propKey] = false;
      this.getSearchOption();
    }
  }
  public destroy(): void {
    clearInterval(this.fillIntervalId);
  }
  public async mounted(): Promise<void> {
    await this.initRightData();
    this.handleinitUserGroupRoles(this.userGroupRoles);
  }
  // 查询筛选列表
  private async queryuserlist(
    searchParam: any,
    isAllChecked?: boolean
  ): Promise<void> {
    const res = (await this.userGroupService.postusergroup(searchParam)) || [];
    this.leftdata = res.slice(0);
    if (isAllChecked) {
      this.leftCheckAllChange(true);
    }
    // 初始化data数组length
    this.initDataCount = res.length;
    this.initRightData('search');
  }

  private async queryUser(event: any): Promise<void> {
    this.leftCheckList = [];
    const keyword = isString(event)
      ? event
      : (event as any).currentTarget.querySelector('input').value;
    if (keyword) {
      let division: string;
      switch (this.type) {
        case 'Pharma':
          division = '0';
          break;
        case 'HR Core':
          division = '2';
          break;
        default:
          division = '0';
      }
      try {
        this.loading = true;
        const res = await this.userGroupService.getusergroupQuickusers({
          division,
          keyword
        });

        this.leftdata = res || [];
        this.leftCheckList = filter(this.leftCheckList, (e) =>
          some(this.leftdata, (el) => el.userId === e.userId)
        );
        this.initRightData();
      } finally {
        this.loading = false;
      }
    } else {
      this.leftdata = [];
    }
    this.isSearch = false;
  }

  // 初始化右侧列表
  private initRightData(type?: string): void {
    // 初始化type有值的时候 通过搜索初始化右边穿梭 初始化type没有值的时候常规初始化
    if (!type) {
      this.rightdata = this.rightInitData.slice(0);
    } else {
      this.rightInitData = [];
      this.rightdata = [];
    }

    this.rightdata.forEach((outitem: any) => {
      const index = this.leftdata.findIndex(
        (item: any) => item.userId === outitem.userId
      );
      if (index !== -1) {
        this.leftdata.splice(index, 1);
      }
    });
  }

  // 获取筛选下拉
  private async getSearchOption(val?: Dict<any>): Promise<void> {
    var data_:any = '';
    this.searchConfig =
      this.type === 'Pharma' ? PharmaSearchConfig : OncoSearchConfig;
    this.searchResults = await this.userGroupService.postcondition(
      'pharma',
      data_
    );
    // const keys = Object.keys(res);
    // this.searchConfig.forEach((item: any) => {
    //   item.options = this.searchResults[item.prop];
    // });
    this.startFillSearchConf();
  }

  private startFillSearchConf(): void {
    clearInterval(this.fillIntervalId);
    forEach(this.searchConfig, (item: any) => {
      item.options = [];
    });
    let index = 0;
    this.pushSearchResults(index);
    index += 50;
    this.fillIntervalId = setInterval(() => {
      this.pushSearchResults(index);
      // forEach(that.searchConfig, (item: any) => {
      //   if ((that.searchResults[item.prop] as any[]).length < index) {
      //     item.options.push(...(that.searchResults[item.prop] as any[]).slice(index, index + 50));
      //   }
      // });
      index += 50;
    }, 500);
  }

  private pushSearchResults(index: number): void {
    for (const item of this.searchConfig as any) {
      if ((this.searchResults[item.prop] as any[]).length > index) {
        item.options.push(
          ...(this.searchResults[item.prop] as any[]).slice(index, index + 50)
        );
        // forEach(this.searchForm[item.prop], (val) => {
        //   if (some(item.options, (op) => op.value === val)) {
        //     this.getLabel(item.options, val, item.prop, true);
        //   }
        // });
      } else {
        forEach(this.searchForm[item.prop], (val) => {
          // if (some(item.options, (op) => op.value === val)) {
          this.getLabel(item.options, val, item.prop, true);
          // }
        });
      }
    }
  }
}
