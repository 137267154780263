

















































import { Inject } from '@cds/common';
import userGroupChoose from '@/components/UserGroup/userGroupChoose.vue';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { UserGroupService } from '@/services/user-group-service';

@Component({
  components: {
    userGroupChoose
  }
})
export default class addClassify extends Vue {
  @Prop({ default: true }) private title!: String; 
  @Prop({ default: true }) private dialogFormVisible!: Boolean; 
  @Prop({ default: true }) private groupList!: any; 
  
  public labelPosition: String = 'left';
  public chooseTitle: String = '选择分组用户';
  public formVisible: Boolean = false;
  public disabled: Boolean = false;
  public type:any = '';
  public groupChat:any = [];
  public groupTag:any = [];
  public resource:any='0';
  public form:any = {
    welcomeSpeech:''
  };
  
  public rules: any = {
          name: [
            { required: true, message: '请输入群名称', trigger: ['change','blur'] }],
          owner: [
            { required: true, message: '请输入群主', trigger: ['change','blur'] }],
          ownerBackup: [
            { required: true, message: '请输入备选群主', trigger: ['change','blur'] }], 
          welcomeSpeech: [
            { required: true, message: '请输入群聊欢迎语', trigger: ['change','blur'] }],             
          tagName: [
            { required: true, message: '请输入标签名称', trigger: 'blur' }
          ],            
  }
  @Inject(UserGroupService) private userGroupService!: UserGroupService;
  public async created(): Promise<void> { 
      this.groupChat = this.groupList.groupChat;
      this.groupTag = this.groupList.groupTag;
      this.form.groupId = this.groupList.id;
      //如果创建了群聊
      if(this.groupChat.name!=null && this.groupChat.name!=''){
        this.form = this.groupChat;
        this.resource = '0';
        this.disabled = true;
      }
      //如果创建了标签
      if(this.groupTag.tagName!=null && this.groupTag.tagName!=''){
        this.form = this.groupTag;
        this.resource = '1';
        this.disabled = true;
      }
  }
  public changeValue(val:any){
    let reset = (this.$refs.form as any);
    reset.clearValidate();
  }

  public async submitForm (){     
      const addRef = (this.$refs.form as any)  
      addRef.validate( async (valid: any) => {
          if (valid) { 
             if(this.resource == 0){ //创建群聊
                let createChat = await this.userGroupService.saveOrUpdate(this.form);
                if(createChat!=undefined && createChat !=null){
                  this.$message({
                    message: '保存成功',
                    type: 'success'
                  });   
                }
              }else{//创建标签
                let createTag = await this.userGroupService.groupTag(this.form);    
                if(createTag!=undefined && createTag !=null){
                  this.$message({
                    message: '保存成功',
                    type: 'success'
                  });   
                }       
              } 
             this.cancel()   
          } else {
            console.log('error submit!!');
            return false;
          }
      });
     
  }
    //选择可见范围
  public choose(type:any){
    this.type = type;
    this.formVisible = true;
  }
  //得到子组件的值
  public close(e: any): void {
    if(e==false){
      this.formVisible = false;  
      return;
    }
    if(this.type == '1'){
      this.$set(this.form, 'owner',  e.employeeId)
    }
    if(this.type == '2'){
      this.$set(this.form, 'ownerBackup',  e.employeeId)
    }
  }
  //返回
  @Emit('selectChange')
  public cancel(): Boolean {
    return false
  }
  public getRowKeys(row:any) {
    return row.id;
  }
   
}
